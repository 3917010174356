
import {defineComponent, ref, watch, onMounted} from 'vue';
import {ElConfigProvider} from 'element-plus';
import ptBr from 'element-plus/lib/locale/lang/pt-br';
import useEmitter from '@/composables/Emmiter'
import AuthService from "@/services/AuthService";
import ApiService from '@/services/ApiService';
import {useStore} from 'vuex';
import {Actions} from "@/store/enums/StoreEnums";
import FilterBase from '@/layout/header/partials/filters/FilterBase.vue';
import { grupolist } from "@/services/GrupoService";

export default defineComponent({
  name: "filtro-grupos",
  components: {
    FilterBase,
    ElConfigProvider,
  },
  setup(props, {emit}) {

    const store = useStore();
    const disabledClick = ref(false);
    let valorInicialCodConcessionaria;
    if (window.localStorage.getItem("id_usuario")) {
      valorInicialCodConcessionaria = window.localStorage.getItem("filtroConcessionaria") ?? "";
    } else {
      valorInicialCodConcessionaria = window.sessionStorage.getItem("filtroConcessionaria") ?? "";
    }
    const codConcessionariaSelected: any = ref("");
    const userInfo = AuthService.getUsuarioAuth();
    const token = userInfo.token;
    const emitter = useEmitter();
    const activeModal = ref(false);
    const closeModal = ref(false);
    const nomeServico: any = ref("");
    const label = ref("Faça filtros por grupo");
    const lastEmitIsNull = ref(false);
    const modalFiltro: any = ref();
    const showClose = ref(false)
    const motivoSelected: any = ref([]);
    const statusSelected: any = ref([]);
    const pecaSelected: any = ref([]);
    const optionsConcessionaria: any = ref([]);
    const optionsMotivosReprovacao: any = ref([]);
    const optionsPecas: any = ref([]);
    const codigoPecaServico = ref("");
    const codigoMOServico = ref("");
    const situacaoServico = ref(1);
    const codAtria = ref("");
    const codgruposOptions:any = ref([])
    const dadosGrupo :any = ref([])
    const filter = ref(['']);
    const loading: any = ref({
      concessionaria: false
    });
    const showGoToBack = ref(false);

    onMounted(() => {
        buscarCodgrupo(dadosGrupo)
    });

    async function buscarCodgrupo(codgrupo) {
      let response = await grupolist(codgrupo);
       codgruposOptions.value = response.map(codgrupo => {
          const option =  {
            text: codgrupo.nome,
            value: codgrupo.codGrupo
          }
          return option;
        })
      console.log("codgruposOptions",codgruposOptions.value)
    } 

    async function emiteMudanca() {
      label.value = "Faça filtros por grupo";
      showGoToBack.value = false;
      await ApiService.valideTokenTime();
      emitter.emit("filtrar-crud-grupo", {
        filtrosServicos: {
          dadosGrupo: dadosGrupo.value 
        }
      });
      closeModal.value = !closeModal.value;
      return;
    }

    return {
      activeModal,
      closeModal,
      showClose,
      modalFiltro,
      loading,
      ptBr,
      emitter,
      emiteMudanca,
      lastEmitIsNull,
      optionsConcessionaria,
      optionsMotivosReprovacao,
      optionsPecas,
      codConcessionariaSelected,
      motivoSelected,
      pecaSelected,
      showGoToBack,
      label,
      statusSelected,
      disabledClick,
      nomeServico,
      codigoPecaServico,
      codigoMOServico,
      situacaoServico,
      codAtria,
      codgruposOptions,
      dadosGrupo,
      filter,
      buscarCodgrupo
    }
  }
});

